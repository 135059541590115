<template>
    <section>
        <TitleBar title="Üretim Onayı Detayları" :modi="modi" />

        <b-tabs>
            <b-tab-item class="columns is-multiline" label="Genel">
                <b-field class="column is-6 box" label="Tanım" grouped group-multiline>
                    <b-field class="column is-12" label="ID">
                        <b-input v-model="productionconfirm.id" disabled></b-input>
                    </b-field>
                    <b-field class="column is-12" label="İş Emri">
                        <ProductionOrderAutofill v-model="productionconfirm.productionorder" :disabled="modi == 'show'" />
                    </b-field>
                    <b-field class="column is-12" label="Üretilen Parti No">
                        <b-input v-model="productionconfirm.lotnum" :disabled="modi == 'show' || modi == 'edit'"></b-input>
                    </b-field>
                </b-field>

                <b-field class="column is-6 box" label="Üretim Bilgileri" grouped group-multiline>
                    <b-field class="column is-12" label="Sorumlu Personel">
                        <b-input v-model="productionconfirm.staff" :disabled="modi == 'show'"></b-input>
                    </b-field>
                    <b-field class="column is-12" label="Üretim Başlangıç">
                        <b-datetimepicker
                            size="is-small"
                            icon="calendar-today"
                            placeholder="Seciniz..."
                            v-model="productionconfirm.prodstart"
                            :timepicker="{ hourFormat: '24' }"
                            :disabled="modi == 'show'"
                            horizontal-time-picker
                            rounded
                        >
                        </b-datetimepicker>
                    </b-field>
                    <b-field class="column is-12" label="Üretim Bitiş">
                        <b-datetimepicker
                            size="is-small"
                            icon="calendar-today"
                            placeholder="Seciniz..."
                            v-model="productionconfirm.prodend"
                            :timepicker="{ hourFormat: '24' }"
                            :disabled="modi == 'show'"
                            horizontal-time-picker
                            rounded
                        >
                        </b-datetimepicker>
                    </b-field>
                    <b-field class="column is-12" label="Üretilen Miktar (KG)">
                        <b-numberinput
                            type="is-link is-light"
                            placeholder="0.00"
                            controls-alignment="right"
                            controls-position="compact"
                            v-model="productionconfirm.quantity"
                            :disabled="modi == 'show' || modi == 'edit'"
                            expanded
                        ></b-numberinput>
                    </b-field>
                </b-field>

                <b-field class="column is-12 box" label="Notlar" grouped group-multiline>
                    <b-input class="column is-12" type="textarea" v-model="productionconfirm.note" :rows="5" :disabled="modi == 'show'"></b-input>
                </b-field>

                <b-field class="column is-12 box" label="Sarf Kalemleri Tablosu" grouped group-multiline>
                    <b-field class="column is-12" position="is-right" grouped group-multiline>
                        <b-button class="column" type="is-success is-light" icon-left="plus" label="Ekle" @click="bom_add" :disabled="modi == 'show'" />
                        <b-button
                            class="column ml-1"
                            type="is-info is-light"
                            icon-left="format-color-fill"
                            @click="bom_fillfrommaterial"
                            label="Reçeteden Getir"
                            :disabled="modi == 'show' || !productionconfirm.productionorder.material.id"
                        />
                        <b-button
                            class="column ml-1"
                            type="is-info is-light"
                            icon-left="format-color-fill"
                            @click="bom_fillfromproductionorder"
                            label="İş Emrinden Getir"
                            :disabled="modi == 'show' || !productionconfirm.productionorder.material.id"
                        />
                        <b-button class="column ml-1" type="is-warning is-light" icon-left="import" label="Temizle" disabled />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Yazdır" />
                        <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" />
                    </b-field>
                    <b-table class="column is-12" :key="$store.state.ui.flush" :mobile-cards="false" :data="productionconfirm.boms" striped narrowed>
                        <template slot="empty">
                            <div class="is-12 has-text-centered">
                                Ürün ağacı kaydı bulunamadı.
                            </div>
                        </template>
                        <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-5) }}</b-table-column>
                        <b-table-column field="item.code" label="Malzeme Kodu" v-slot="props" sortable>
                            <MaterialAutofill v-model="props.row.item" :disabled="!!props.row.id" />
                        </b-table-column>
                        <b-table-column field="item.name" label="Malzeme Açıklaması" v-slot="props" sortable>{{ props.row.item.name }}</b-table-column>
                        <b-table-column field="lotnum" label="Parti No" v-slot="props" sortable>
                            <LotAutofill v-model="props.row.lotnum" @value="(v) => (props.row.lotnum = v)" :id_material="props.row.item.id" :disabled="!!props.row.id" />
                        </b-table-column>
                        <b-table-column field="quantity" label="Miktar (KG)" v-slot="props" numeric sortable>
                            <b-numberinput
                                type="is-link is-light"
                                placeholder="0.00"
                                controls-alignment="right"
                                controls-position="compact"
                                v-model="props.row.quantity"
                                :min-step="0.001"
                                :controls="!props.row.id"
                                :disabled="!!props.row.id"
                                expanded
                            ></b-numberinput>
                        </b-table-column>
                        <b-table-column field="action" label="Aksiyon" v-slot="props">
                            <b-button type="is-danger is-light" icon-left="delete" @click="bom_delete(props.row.id, props.index)" :disabled="modi == 'show'" />
                        </b-table-column>
                    </b-table>
                </b-field>
            </b-tab-item>

            <b-tab-item label="Kayıt Geçmişi" :disabled="!productionconfirm.id">
                <MetaDetails v-if="$route.query.id" />
            </b-tab-item>
        </b-tabs>

        <b-field position="is-right">
            <b-button class="ml-1" type="is-success is-light" icon-left="check" label="Kaydet" :disabled="modi == 'show'" @click="productionconfirm_save" />
            <b-button class="ml-1" type="is-danger is-light" icon-left="close" label="İptal" @click="productionconfirm_cancel" />
        </b-field>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import LotAutofill from "@/components/LotAutofill.vue";
import MaterialAutofill from "@/components/MaterialAutofill.vue";
import ProductionOrderAutofill from "@/components/ProductionOrderAutofill.vue";
import MetaDetails from "@/components/MetaDetails.vue";

export default {
    name: "ProductionConfirmDetails",
    components: {
        TitleBar,
        LotAutofill,
        MaterialAutofill,
        ProductionOrderAutofill,
        MetaDetails
    },
    data: () => ({
        modi: "",
        productionconfirm: {
            id: "",
            productionorder: {
                material: {}
            },
            boms: []
        }
    }),
    created() {
        this.productionconfirm.id = this.$route.query.id || "";
        this.modi = this.$route.query.modi || "add";
        this.productionconfirm_read();
    },
    methods: {
        productionconfirm_read() {
            if (this.modi == "add") return;
            this.$apollo.queries.readProductionConfirm.start();
        },
        productionconfirm_save() {
            var err = "";
            var payload = this.productionconfirm;

            payload.boms.forEach((e) => delete e.bom_quantity);

            if (!payload.productionorder) err = "İş emri boş olamaz.";
            if (!payload.prodstart) err = "Üretim başlangıcı boş olamaz.";
            if (!payload.prodend) err = "Üretimi bitiş tarihi boş olamaz.";
            if (!payload.quantity) err = "Üretim miktarı boş olamaz.";
            if (!payload.lotnum) err = "Üretilen parti no boş olamaz.";

            if (err)
                return this.$buefy.snackbar.open({
                    type: "is-warning",
                    message: err,
                    position: "is-bottom-right"
                });

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($productionconfirm: String) {
                            saveProductionConfirm(productionconfirm: $productionconfirm)
                        }
                    `,
                    variables: {
                        productionconfirm: JSON.stringify(payload)
                    }
                })
                .then(() => {
                    this.$buefy.snackbar.open({
                        type: "is-success",
                        message: "Başarılı",
                        position: "is-bottom-right"
                    });
                    return this.$router.push({ path: "/uretim/onay/liste" });
                })
                .catch(() => {
                    return this.$buefy.snackbar.open({
                        type: "is-danger",
                        message: "Bir hata oluştu.",
                        position: "is-bottom-right"
                    });
                });
        },
        productionconfirm_cancel() {
            this.$router.push({ path: "/uretim/onay/liste" });
        },
        bom_add() {
            this.productionconfirm.boms.push({
                id: "",
                item: {},
                lotnum: "",
                quantity: 0.0
            });
        },
        bom_delete(id, index) {
            if (!id) this.productionconfirm.boms.splice(index, 1);
            else;
        },
        bom_fillfrommaterial() {
            if (this.modi == "show") return;

            this.productionconfirm.boms = [];
            this.$apollo.queries.readMaterials.start();
            this.$apollo.queries.readMaterials.refetch();
        },
        bom_fillfromproductionorder() {
            if (this.modi == "show") return;

            this.productionconfirm.boms = [];
            this.$apollo.queries.readProductionOrders.start();
            this.$apollo.queries.readProductionOrders.refetch();
        }
    },
    apollo: {
        readProductionConfirm: {
            query: gql`
                query readProductionConfirms($config: String) {
                    readProductionConfirms(config: $config) {
                        id
                        productionorder {
                            id
                            material {
                                id
                                code
                            }
                            name
                        }
                        quantity
                        lotnum
                        staff
                        prodstart
                        prodend
                        note
                        boms {
                            id
                            item {
                                id
                                code
                                name
                            }
                            quantity
                            lotnum
                        }
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 1,
                        sorts: [],
                        filters: { id: this.productionconfirm.id }
                    })
                };
            },
            update(data) {
                this.$apollo.queries.readProductionConfirm.stop();
                this.productionconfirm = data.readProductionConfirms[0];
                this.productionconfirm.prodstart = new Date(this.productionconfirm.prodstart);
                this.productionconfirm.prodend = new Date(this.productionconfirm.prodend);

                if (this.modi == "ref") {
                    delete this.productionconfirm.id;
                    for (var item of this.productionconfirm.boms) delete item.id;
                }
            }
        },
        readMaterials: {
            query: gql`
                query readMaterials($config: String) {
                    readMaterials(config: $config) {
                        boms {
                            id
                            item {
                                id
                                code
                                name
                            }
                            quantity
                        }
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 1,
                        sorts: [],
                        filters: {
                            id: this.productionconfirm.productionorder.material.id
                        }
                    })
                };
            },
            update(data) {
                this.$apollo.queries.readMaterials.stop();

                const bom_sum = data.readMaterials[0].boms.reduce((a, c) => a + c.quantity, 0);
                data.readMaterials[0].boms.forEach((e) => {
                    this.productionconfirm.boms.push({
                        id: "",
                        item: {
                            id: e.item.id,
                            code: e.item.code,
                            name: e.item.name
                        },
                        lotnum: "",
                        bom_quantity: e.quantity,
                        quantity: parseFloat(((e.quantity * this.productionconfirm.quantity) / bom_sum).toFixed(4))
                    });
                });
            }
        },
        readProductionOrders: {
            query: gql`
                query readProductionOrders($config: String) {
                    readProductionOrders(config: $config) {
                        boms {
                            id
                            item {
                                id
                                code
                                name
                            }
                            quantity
                        }
                    }
                }
            `,
            variables() {
                return {
                    config: JSON.stringify({
                        skip: 0,
                        take: 1,
                        sorts: [],
                        filters: {
                            id: this.productionconfirm.productionorder.id
                        }
                    })
                };
            },
            update(data) {
                this.$apollo.queries.readProductionOrders.stop();

                const bom_sum = data.readProductionOrders[0].boms.reduce((a, c) => a + c.quantity, 0);
                data.readProductionOrders[0].boms.forEach((e) => {
                    this.productionconfirm.boms.push({
                        id: "",
                        item: {
                            id: e.item.id,
                            code: e.item.code,
                            name: e.item.name
                        },
                        lotnum: "",
                        bom_quantity: e.quantity,
                        quantity: parseFloat(((e.quantity * this.productionconfirm.quantity) / bom_sum).toFixed(4))
                    });
                });
            }
        }
    }
};
</script>
